import "@styles/globals.css";
import { type AppProps } from "next/app";
import { NextSeo } from "next-seo";
import { useEffect } from "react";
import Script from "next/script";

export default function App({ Component, pageProps }: AppProps) {
  useEffect(() => {
    console.log("%c Zerio was here :)", "background: #000; color: #20C20E");
  }, []);

  return (
    <>
      <Script
        src="https://www.googletagmanager.com/gtag/js?id=G-PS9CRNBZPC"
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(...args){window.dataLayer.push(args);}
          gtag('js', new Date());

          gtag('config', 'G-PS9CRNBZPC', {
            cookie_flags: 'max-age=7200;secure;samesite=none'
          });
        `}
      </Script>

      <NextSeo
        title="Bolaget Records"
        description="Bolaget Records är ett uppkommande Svenskt Band grundat i Falun under 2019 som nu tar Sverige som en storm med deras musik."
        additionalLinkTags={[
          {
            rel: "apple-touch-icon",
            href: "https://bolagetrecords.se/logos/touch-icon-ipad.webp",
            sizes: "76x76",
          },
          {
            rel: "manifest",
            href: "https://bolagetrecords.se/manifest.json",
          },
        ]}
        openGraph={{
          type: "website",
          images: [
            {
              url: "https://bolagetrecords.se/logos/logo.webp",
              alt: "Bolaget Records Logo",
              type: "image/webp",
            },
          ],
          siteName: "Bolaget Records",
        }}
      />

      <Component {...pageProps} />
    </>
  );
}
